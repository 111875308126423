/* -------------------------------------------------------------------------- */
/*                                   GLOBAL                                   */
/* -------------------------------------------------------------------------- */

:root {
  --bg-dark-0: #121212;
  --bg-dark-1: #1d1d1d;
  --bg-dark-2: #222222;
  --bg-dark-3: #242424;
  --bg-dark-4: #272727;
  --bg-dark-6: #2c2c2c;
  --bg-dark-8: #2d2d2d;
  --bg-dark-12: #323232;
  --bg-dark-16: #353535;
  --bg-dark-24: #373737;

  --accent-blue-background-1: #003fec;
  --accent-blue-background-2: #0096ec;
  --accent-blue-1: #1681be;
  --accent-blue-2: #0080ca;

  --accent-green-1: #08b877;
  --accent-green-2: #0a8f6c;

  --accent-red-1: #e74f4f;
  --accent-red-2: #be4444;

  --font-color-primary: #f1f1f1;
  --font-color-secondary: #b6b6b6;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: var(--bg-dark-0);
}

::-webkit-scrollbar-thumb {
  background: var(--accent-blue-2);
  border-radius: 5px;
}

body {
  background-color: var(--bg-dark-8);
  margin: 0;
}

body, button, input {
  color: var(--font-color-primary);
  font-family: 'Open Sans', sans-serif;
}

a, a:visited {
  color: var(--accent-blue-1);
}

/* -------------------------------------------------------------------------- */
/*                                    HOME                                    */
/* -------------------------------------------------------------------------- */

.home-main {
  background: linear-gradient(var(--accent-blue-background-1), var(--accent-blue-background-2));
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 35vh;
}

.home-logo {
  height: 40vh;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.home-description {
  text-align: center;
  font-size: 20px;
  margin: 0 10px;
}

.home-name {
  font-size: 3em;
  font-weight: bold;
  margin: 0;
}

.home-sections {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-linkbutton {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  font-size: 20px;
  font-weight: 400;
  transition: 200ms ease-in-out background-color;
  outline: none;
}

.home-linkbutton:hover {
  cursor: pointer;
  background-color: #ffffff1a;
}

.home-terms {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

/* -------------------------------------------------------------------------- */
/*                                    Terms                                   */
/* -------------------------------------------------------------------------- */

.terms-container {
  margin: 20px;
}

/* -------------------------------------------------------------------------- */
/*                               LOGGED IN USER                               */
/* -------------------------------------------------------------------------- */

.liu-container {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 9999;
}

.liu-img {
  border-radius: 50%;
  width: 60px;
  box-shadow: 5px 10px 18px rgba(0, 0, 0, .3);
  background-color: var(--bg-dark-2);
  cursor: pointer;
}

.liu-popup {
  background-color: var(--bg-dark-2);
  border-radius: 10px;
  box-shadow: 5px 10px 18px rgba(0, 0, 0, .3);
  margin: 0;
  margin-top: 5px;
  position: relative;
}

.liu-popup::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 29%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--bg-dark-2) transparent;
}

.liu-popup-category {
  background-color: var(--bg-dark-2);
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  transition: 150ms ease-in-out background-color;
}

.liu-popup-category:hover {
  background-color: var(--bg-dark-8);
}

.liu-popup-category-name {
  margin: 0px;
  text-decoration: none;
  color: unset;
  user-select: none;
}

.liu-popup-category-name-logout {
  color: #f14545;
}

/* -------------------------------------------------------------------------- */
/*                                    DASH                                    */
/* -------------------------------------------------------------------------- */

.dash-loading {
  margin: auto;
  margin-top: calc(50vh - 80px * 0.9);
  border: 5px solid transparent;
  border-top: 5px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 0.5s linear infinite;
}

.dash {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.dash-user {
  background-color: var(--bg-dark-3);
  border-radius: 5px;
  padding: 20px;
  width: 600px;
  max-width: 85vw;
  position: relative;
}

.dash-user-img {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 75px;
  border-radius: 50%;
  background-color: var(--bg-dark-1);
}

.dash-user-name {
  margin: 5px;
}

.dash-user-key {
  font-weight: 500;
  margin-left: 5px;
  margin-bottom: 2px;
}

.dash-user-value {
  font-weight: 200;
  margin-left: 5px;
  margin-top: 2px;
}

/* -------------------------------------------------------------------------- */
/*                                     NAV                                    */
/* -------------------------------------------------------------------------- */

.nav-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-link {
  color: var(--font-color-primary);
  text-decoration: none;
  margin: 10px;
}

.nav-link-active {
  text-decoration: underline;
}

.nav-link:hover {
  text-decoration: underline;
}

/* -------------------------------------------------------------------------- */
/*                                   PRESETS                                  */
/* -------------------------------------------------------------------------- */

.presets-title {
  text-align: center;
}

.presets-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.presets-preset {
  background-color: var(--bg-dark-3);
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
}

.presets-preset-name {
  text-align: center;
  margin: 0;
  margin-bottom: 5px;
}

.presets-preset-img {
  width: 580px;
  max-width: 90vw;
  border-radius: 5px;
  margin-top: 5px;
}

.presets-preset-createdby, .presets-preset-usedby {
  margin: 0;
  font-weight: 300;
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .presets-preset-createdby, .presets-preset-usedby {
    font-size: 16px;
  }
}

.presets-preset-button {
  border-radius: 5px;
  border: none;
  padding: 5px 10px;
  margin-top: 5px;
  font-size: 18px;
  font-weight: 400;
  transition: 200ms ease-in-out background-color;
  float: right;
  cursor: pointer;
}

.presets-preset-remove {
  background-color: var(--accent-red-1); 
}

.presets-preset-remove:hover {
  background-color: var(--accent-red-2);
}

.presets-preset-add {
  background-color: var(--accent-green-1); 
}

.presets-preset-add:hover {
  background-color: var(--accent-green-2);
}

.presets-preset-notfound-container {
  text-align: center;
}

.presets-preset-notfound-title {
  font-size: 50px;
  margin-bottom: 5px;
}

.presets-preset-notfound-description {
  font-size: 30px;
  margin-top: 5px;
}

/* -------------------------------------------------------------------------- */
/*                                 PRESET LINK                                */
/* -------------------------------------------------------------------------- */

.preset-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

/* -------------------------------------------------------------------------- */
/*                               BROWSE PRESETS                               */
/* -------------------------------------------------------------------------- */

.browse-presets {
  display: flex;
  justify-content: center;
}

.browse-presets .presets-preset {
  scroll-snap-align: center;
}

.browse-presets .presets-preset-img {
  max-width: 85vw;
  max-height: 85vh;
}

/* -------------------------------------------------------------------------- */
/*                                    MODAL                                   */
/* -------------------------------------------------------------------------- */

.modal-overlay {
  z-index: 999;
  background-color: black;
  opacity: 0.7;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.modal-container {
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-dark-2);
  border-radius: 10px;
  padding: 10px;
  box-shadow: 5px 10px 18px rgba(0, 0, 0, .3);
}

/* media query for mobile and desktop users that changes the max width */
@media screen and (max-width: 768px) {
  .modal-container {
    width: 80vw;
  }
}

.modal-title {
  text-align: center;
  margin: 0;
}

.modal-description {
  text-align: center;
  margin: 5px;
}

.modal-control {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-top: 10px;
}

.modal-button {
  background-color: var(--accent-blue-1);
  border-radius: 5px;
  border: none;
  padding: 5px 15px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 18px;
  font-weight: 400;
  transition: 200ms ease-in-out background-color;
  cursor: pointer;
}

.modal-button:hover {
  background-color: var(--accent-blue-2);
}

.modal-yesbutton {
  background-color: var(--accent-green-1);
}

.modal-nobutton {
  background-color: var(--accent-red-1);
}

.modal-yesbutton:hover {
  background-color: var(--accent-green-2);
}

.modal-nobutton:hover {
  background-color: var(--accent-red-2);
}

.modal-closex {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px;
  margin: 0;
  margin-right: 5px;
  cursor: pointer;
  opacity: 0.8;
}

.modal-closex:hover {
  opacity: 1;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
